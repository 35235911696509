body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(158, 114, 114, 0.2);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.541);
  /* outline: 1px solid slategrey; */
  border-radius: 10px;
}

/* @font-face {
  font-family: "MyFont";
  src: local("MyFont"), url(Assets/font/sanfranciscodisplay-regular-webfont.woff) format("woff");
} */

@font-face {
  font-family: "SF Pro Display Regular";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Regular"), url(Assets/font/SFPRODISPLAYREGULAR.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display Ultralight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Ultralight Italic"), url(Assets/font/SFPRODISPLAYULTRALIGHTITALIC.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Thin Italic"), url(Assets/font/SFPRODISPLAYTHINITALIC.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Light Italic"), url(Assets/font/SFPRODISPLAYLIGHTITALIC.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display Medium";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Medium"), url(Assets/font/SFPRODISPLAYMEDIUM.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Semibold Italic"), url(Assets/font/SFPRODISPLAYSEMIBOLDITALIC.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display Bold";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Bold"), url(Assets/font/SFPRODISPLAYBOLD.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display Heavy Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Heavy Italic"), url(Assets/font/SFPRODISPLAYHEAVYITALIC.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Display Black Italic"), url(Assets/font/SFPRODISPLAYBLACKITALIC.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Text Regular";
  font-style: normal;
  font-weight: normal;
  src: local("SF Pro Text Regular"), url(Assets/font/SFProTextRegular.woff) format("woff");
}
